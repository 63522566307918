import React, { useState, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Button, Tooltip, Fab, Avatar, withStyles } from "@material-ui/core";
import axios from "axios";

import Outline from "../Outline";
import { useApi, usePermission } from "../../../../hooks";
import { Flex, Tipografia, createSnackbar, createDialogConfirm, AjaxBlackout } from "../../../../_common";
import FormObservacoes from "./FormObservacoes";
import Observacoes from "./Observacoes";
import permissions from "../../../../_common/permissions";
import Icone from "../../../../_common/icones/Logo_Redondo_RD_Station.svg";
import IconeBlackout from "../../../../_common/icones/Logo_Redondo_RD_Blackout.gif";
import iconeLogoNome from "../../../../_common/icones/Logo_RD_Station.svg";
import Summarize from "../../../../_common/icones/Summarize";
import Grading from "../../../../_common/icones/Grading";

const messages = defineMessages({
  ALTERACOES_SALVAS: { defaultMessage: "Alterações salvas com sucesso" },
  OCORREU_ERRO: { defaultMessage: "Ocorreu um erro" },
  NAO_HA_OBSERVACOES: { defaultMessage: "Não há observações" },
});

function ObservacoesOutline({ id, classes, lead, isEditing, ...props }) {
  const intl = useIntl();
  const [observacoes, loading, salvar] = useApi(`/api/pipeline/lead/observacoes?id=${id}`, descricao => ({
    descricao,
  }));
  const [aberto, setAberto] = useState(false);
  const [permiteResumo, setPermiteResumo] = useState(false);
  const [textoExpandido, setTextoExpandido] = useState(false);
  const canEdit = usePermission(permissions.ALTERAR_OBSERVACOES, lead);
  const textoParcial = observacoes?.slice(0, 300);
  const expandirLabel = textoExpandido
    ? intl.formatMessage({ defaultMessage: "Menos" })
    : intl.formatMessage({ defaultMessage: "Mais" });

  const handleExpandir = () => {
    setTextoExpandido(!textoExpandido);
  };

  useEffect(
    () => {
      setPermiteResumo(observacoes?.trim().length >= 1000);
    },
    [observacoes]
  );

  const resumoMentorIA = () => {
    if (permiteResumo) {
      AjaxBlackout.Show(IconeBlackout);
      axios
        .post(`/api/pipeline/IntegracaoMagicWrite/resumir`, { descricao: observacoes })
        .then(response => {
          AjaxBlackout.Hide();
          createDialogConfirm({
            text: response.data,
            title: intl.formatMessage({ defaultMessage: "Resumo" }),
            acceptLabel: intl.formatMessage({ defaultMessage: "Sobrescrever" }),
            cancelLabel: intl.formatMessage({ defaultMessage: "Fechar" }),
            tamanhoMaximo: "md",
            avatar: iconeLogoNome,
            callback: accepted => (accepted ? salvarObservacao(response.data, true) : setAberto(false)),
          });
        })
        .catch(() => {
          AjaxBlackout.Hide();
          createSnackbar(intl.formatMessage(messages.OCORREU_ERRO));
        });
    }
  };

  const correcaoMentorIA = () => {
    AjaxBlackout.Show(IconeBlackout);
    axios
      .post(`/api/pipeline/IntegracaoMagicWrite/corrigir`, { descricao: observacoes })
      .then(response => {
        AjaxBlackout.Hide();
        createDialogConfirm({
          text: response.data,
          title: intl.formatMessage({ defaultMessage: "Correção" }),
          acceptLabel: intl.formatMessage({ defaultMessage: "Sobrescrever" }),
          cancelLabel: intl.formatMessage({ defaultMessage: "Fechar" }),
          tamanhoMaximo: "md",
          avatar: iconeLogoNome,
          callback: accepted => (accepted ? salvarObservacao(response.data, true) : setAberto(false)),
        });
      })
      .catch(() => {
        AjaxBlackout.Hide();
        createSnackbar(intl.formatMessage(messages.OCORREU_ERRO));
      });
  };

  const salvarObservacao = texto => {
    setAberto(false);
    salvar(texto)
      .then(success => {
        if (success) {
          document.dispatchEvent(
            new CustomEvent("detalhe-lead/timeline/reloadLogFilterTriggers", { detail: { flag: true } })
          );
        }
        createSnackbar(intl.formatMessage(success ? messages.ALTERACOES_SALVAS : messages.OCORREU_ERRO));
        return success;
      })
      .catch(() => {
        createSnackbar(intl.formatMessage(messages.OCORREU_ERRO));
      });
  };

  return (
    <>
      <Outline
        title={intl.formatMessage({ defaultMessage: "Observações" })}
        form={canEdit ? FormObservacoes : null}
        initialValues={{ observacoes: observacoes || "" }}
        editButtonId="btn-edit-observacoes"
        id="card-Observacoes"
        isEditing={isEditing}
        onSubmit={({ observacoes: o }) =>
          salvar(o)
            .then(success => {
              if (success) {
                document.dispatchEvent(
                  new CustomEvent("detalhe-lead/timeline/reloadLogFilterTriggers", { detail: { flag: true } })
                );
              }
              createSnackbar(intl.formatMessage(success ? messages.ALTERACOES_SALVAS : messages.OCORREU_ERRO));
              return success;
            })
            .catch(() => {
              createSnackbar(intl.formatMessage(messages.OCORREU_ERRO));
            })
        }
        {...props}
      >
        <Flex flexDirection="column">
          {!loading &&
            observacoes && (
              <>
                <Flex
                  className={`${classes.conteudo} ${!textoExpandido ? classes.addDegrade : ""}`}
                  flexDirection="column"
                >
                  <Observacoes texto={textoExpandido ? observacoes : textoParcial} />
                </Flex>
                <Flex justifyContent="space-between" alignItems="end">
                  <Flex justifyContent="flex-start">
                    <Button color="primary" id="buttonExpandir" onClick={handleExpandir}>
                      {expandirLabel}
                    </Button>
                  </Flex>
                  <Flex
                    flexDirection="column"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                    className={classes.containerMenuMentorIA}
                  >
                    <Tooltip title={intl.formatMessage({ defaultMessage: "Opções de texto" })}>
                      <Fab size="small" className={classes.btnMentorIA} onClick={() => setAberto(!aberto)}>
                        <Avatar src={Icone} className={classes.avatar} />
                      </Fab>
                    </Tooltip>
                    {aberto && (
                      <Flex flexDirection="column" alignItems="column" style={{ position: "absolute" }}>
                        <Fab
                          variant="extended"
                          size="small"
                          className={`${classes.btnAcao} ${classes.btnCorrecaoTexto}`}
                          onClick={correcaoMentorIA}
                        >
                          <Grading className={classes.iconFab} />
                          {intl.formatMessage({ defaultMessage: "Corrigir texto" })}
                        </Fab>
                        <Tooltip
                          title={
                            !permiteResumo
                              ? intl.formatMessage({
                                  defaultMessage: "O texto precisa ter o mínimo de 1000 caracteres para ser resumido.",
                                })
                              : ""
                          }
                        >
                          <Fab
                            variant="extended"
                            size="small"
                            className={`${permiteResumo ? classes.btnAcao : classes.btnAcaoDesabilitado} ${
                              classes.btnResumo
                            }`}
                            onClick={resumoMentorIA}
                          >
                            <Summarize className={classes.iconFab} />
                            {intl.formatMessage({ defaultMessage: "Resumir texto" })}
                          </Fab>
                        </Tooltip>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </>
            )}
          {!loading &&
            !observacoes && (
              <Tipografia cor="darkSecondaryText" italico style={{ padding: 20 }}>
                {intl.formatMessage(messages.NAO_HA_OBSERVACOES)}
              </Tipografia>
            )}
        </Flex>
      </Outline>
    </>
  );
}

ObservacoesOutline.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.object,
  lead: PropTypes.object,
  isEditing: PropTypes.func,
};

export default withStyles(theme => ({
  conteudo: {
    whiteSpace: "pre-line",
    textAlign: "justify",
  },
  addDegrade: {
    textAlign: "justify",
    position: "relative",
    overflow: "hidden",
    backgroundImage: "linear-gradient(to bottom, black, white)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  containerMenuMentorIA: {
    position: "static",
  },
  btnMentorIA: {
    position: "absolute",
    width: 30,
    height: 30,
    minHeight: 30,
    zIndex: 1,
  },
  avatar: {
    width: 30,
    height: 30,
  },
  btnAcao: {
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
    color: "#27bec9",
    borderRadius: "4px!important",
    border: "1px solid #27bec9",
    animation: "fadeIn 0.5s",
  },
  btnAcaoDesabilitado: {
    backgroundColor: "#f7f7f7",
    "&:hover": {
      backgroundColor: "#f7f7f7",
    },
    color: "rgba(0, 0, 0, 0.26)",
    borderRadius: "4px!important",
    border: "1px solid #9e9e9e",
    cursor: "default",
  },
  btnCorrecaoTexto: {
    bottom: theme.spacing.unit * 6,
  },
  btnResumo: {
    bottom: theme.spacing.unit * 5,
  },
  iconFab: {
    marginRight: 4,
  },
  blackoutMagicWrite: {
    width: "100%",
    height: "100%",
    backgroundColor: "#f2f2f2",
    zIndex: 9999,
    position: "absolute",
    top: 0,
    opacity: "0.6",
  },
  imgBlackout: {
    position: "fixed",
    top: "43%",
    left: "43%",
  },
}))(ObservacoesOutline);
