import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import { useIntl } from "react-intl";
import { Tipografia } from "../../../../_common";

function Observacoes({ classes, texto = "" }) {
  const intl = useIntl();
  if (!texto)
    return (
      <Tipografia cor="darkSecondaryText" italico style={{ padding: 20 }}>
        {intl.formatMessage({ defaultMessage: "Não há observações" })}
      </Tipografia>
    );

  return texto.split("\n").map((linha, index) => (
    <p key={index} className={classes.text}>
      <Tipografia>{linha}</Tipografia>
    </p>
  ));
}

Observacoes.propTypes = {
  classes: PropTypes.object,
  texto: PropTypes.string,
};

export default withStyles({
  text: { padding: "8px 16px", margin: 0, wordBreak: "break-word" },
})(Observacoes);
