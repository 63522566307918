import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import SwapHoriz from "@material-ui/icons/SwapHoriz";

import { useIntl } from "react-intl";
import { Transferir as Modal } from "./Transferir";
import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";
import { DialogTitleClose } from "../../../../_common";

function Transferir({ callback = x => x, children, filter, leads = [], ...props }) {
  const [modalOpen, setModalOpen] = useState(false);
  const intl = useIntl();
  const close = response => {
    setModalOpen(false);
    callback(response);
  };

  return (
    <Fragment>
      <ActionButton
        id="btn-transferir"
        type="menu"
        label={intl.formatMessage({ defaultMessage: "Transferir" })}
        tooltip={intl.formatMessage({ defaultMessage: "Transferir lead" })}
        icon={<SwapHoriz />}
        onClick={() => setModalOpen(true)}
        claim={permissions.TRANSFERIR}
        leads={leads}
        {...props}
      />
      <Dialog open={modalOpen} onClose={() => close(false)} maxWidth="sm" fullWidth>
        <DialogTitleClose onClose={() => close(false)}>
          {intl.formatMessage({ defaultMessage: "Transferir Leads" })}
        </DialogTitleClose>
        <Modal
          ids={leads.map(lead => lead.id)}
          pvs={leads.map(lead => lead.preVendedorId)}
          usuarioOriginal={leads.map(lead => lead.usuarioOriginalId)}
          onCancel={close}
          onConfirm={close}
          filter={filter}
          {...props}
        />
      </Dialog>
    </Fragment>
  );
}

Transferir.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.func,
  filter: PropTypes.func,
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      grupos: PropTypes.arrayOf(PropTypes.number),
      preVendedorId: PropTypes.number,
      usuarioOriginalId: PropTypes.number,
      situacao: PropTypes.number,
      etapa: PropTypes.shape({ posVenda: PropTypes.bool }),
    })
  ),
};

export default Transferir;
