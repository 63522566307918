import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";

import { useIntl } from "react-intl";
import EditProducts from "./EditProducts";
import { DialogTitleClose } from "../../../../../_common";

ModalEditProducts.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  lead: PropTypes.shape({
    grupos: PropTypes.arrayOf(PropTypes.number),
    preVendedorId: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  vendido: PropTypes.bool,
};

function ModalEditProducts({ onSubmit, onClose, lead, isLoading, isOpen, vendido = false, ...props }) {
  const intl = useIntl();

  return (
    <Dialog PaperProps={{ style: { height: "55%" } }} open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitleClose onClose={onClose}>{intl.formatMessage({ defaultMessage: "Editar produtos" })}</DialogTitleClose>

      <DialogContent>
        <EditProducts lead={lead} vendido={vendido} {...props} isLoading={isLoading} />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
        <Button onClick={onSubmit} color="primary">
          {intl.formatMessage({ defaultMessage: "Salvar" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalEditProducts;
