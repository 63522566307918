import React, { useState, useCallback } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Formik } from "formik";
import useSWR from "swr";
import axios from "axios";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core";

import ListasContext from "../empresa/ListasContext";
import Produtos from "./Produtos";
import ProdutosTotal from "./ProdutosTotal";
import { ModalEditProducts } from "./EditProducts";
import { CardOutline, NonCardOutline } from "../Outline";
import permissions from "../../../../_common/permissions";
import { ActionButton } from "../../../../components";
import { Loading } from "../../../../_common";
import { useEvent, useProdutosSubmit } from "../../../../hooks";
import { appendLabelInativo } from "../../../../_common/utils/string";

ProdutosOutline.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  card: PropTypes.bool,
  lead: PropTypes.object,
  callbackValorTotal: PropTypes.func,
};

function ProdutosOutline({ id, card, lead, callbackValorTotal, classes }) {
  const intl = useIntl();
  const { data: lista, mutate: mutateLista } = useSWR("/api/pipeline/produto/listar", {
    fetcher: (...args) => axios.get(...args).then(({ data }) => data.map(item => appendLabelInativo(item, intl))),
  });
  const { data: produtos, isValidating: isLoading, mutate } = useSWR(`/api/pipeline/lead/produtos?id=${id}`);
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);

  const reloadHandler = useCallback(() => mutate(), [mutate]);

  useEvent("lead.produtos.reload", reloadHandler);

  const Outline = card ? CardOutline : NonCardOutline;

  const { onSubmit } = useProdutosSubmit(id, lista, mutate, mutateLista, () => {
    setIsOpenModalEdit(false);
  });

  const onCloseModalEdit = useCallback(handleReset => {
    setIsOpenModalEdit(false);
    handleReset();
  }, []);

  return (
    <ListasContext.Provider value={{ produtos: lista }}>
      <Formik enableReinitialize initialValues={{ produtos }} onSubmit={onSubmit}>
        {({ handleSubmit, handleReset }) => (
          <Outline
            id="card-ProdutosSugeridos"
            action={
              <Loading size={24} isLoading={isLoading} classes={{ loading: classes.loading }}>
                {!lead?.vendido && (
                  <ActionButton
                    id="btn-edit-produtos"
                    leads={lead}
                    claim={permissions.ALTERAR_DOR_PRODUTO}
                    type="icon"
                    icon={<EditIcon />}
                    tooltip={intl.formatMessage({ defaultMessage: "Editar produtos" })}
                    onClick={() => setIsOpenModalEdit(true)}
                  />
                )}
              </Loading>
            }
            title={intl.formatMessage({ defaultMessage: "Produtos sugeridos" })}
          >
            {isLoading && <Loading isLoading />}
            {!isLoading && (
              <>
                <Produtos produtos={produtos} />
                <ProdutosTotal produtos={produtos} callbackValorTotal={callbackValorTotal} />
                <ModalEditProducts
                  onSubmit={handleSubmit}
                  onClose={() => onCloseModalEdit(handleReset)}
                  lead={lead}
                  isLoading={isLoading}
                  isOpen={isOpenModalEdit}
                />
              </>
            )}
          </Outline>
        )}
      </Formik>
    </ListasContext.Provider>
  );
}

export default withStyles({
  loading: {
    height: "auto",
  },
})(ProdutosOutline);
