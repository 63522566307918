import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { withStyles, Typography } from "@material-ui/core";

import calculatePrice from "./calculatePrice";
import { Flex, Tipografia } from "../../../../_common";
import { formatCurrency } from "../../../../_common/utils/string";

ProdutosTotal.propTypes = {
  produtos: PropTypes.array,
  callbackValorTotal: PropTypes.func,
};

function ProdutosTotal({ produtos = [], callbackValorTotal, classes }) {
  const intl = useIntl();
  const [valorTotal, setValorTotal] = useState(0);

  useEffect(
    () => {
      if (produtos.length > 0) {
        let soma = 0;

        produtos.forEach(produto => {
          const valorCalculado = calculatePrice(
            produto.valor,
            produto.desconto,
            produto.tipoDesconto,
            produto.quantidade
          );
          soma += valorCalculado;
        });

        setValorTotal(soma);
        if (callbackValorTotal) callbackValorTotal(soma);
      }
    },
    [valorTotal, produtos, callbackValorTotal]
  );

  if (produtos.length === 0) return null;

  return (
    <Flex className={classes.container} justifyContent="space-between">
      <Tipografia>{intl.formatMessage({ defaultMessage: "Total" })}</Tipografia>
      <Typography>{formatCurrency(valorTotal)}</Typography>
    </Flex>
  );
}

export default withStyles({
  container: {
    marginTop: "14px",
    padding: "8px 20px",
    "&:hover": { backgroundColor: "rgb(247,247,247)" },
  },
})(ProdutosTotal);
