import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { Button, DialogContent, DialogActions, DialogContentText, Grid } from "@material-ui/core";
import axios from "axios";
import { useIntl } from "react-intl";
import { AjaxBlackout, createSnackbar, Loading } from "../../../../_common";
import useLista from "../../../../hooks/useLista";
import createSnackAPIException from "../../../../_common/utils/snackbar/createSnackbarAPIException";
import AsyncSelect from "../../../../components/AsyncSelect";
import useConfirmarTransferencia from "./useConfirmarTransferencia";

const QTD_MIN_TRANSFERIR_EM_LOTE = 50;
export function Transferir({ ids, pvs, filter, onCancel = x => x, onConfirm = x => x }) {
  const intl = useIntl();
  const [typeSelect] = useState(ids.length > 1 ? "multiple" : "singular");
  const [preVendedorIds, setPreVendedorIds] = useState([]);
  const [preVendedores, loading] = useLista("/api/pipeline/usuario1/ListarNome?tipo=PreVendedor&somenteAtivos=true");
  const { confirmarTransferencia } = useConfirmarTransferencia({
    ids,
    onCancel,
    onSave,
  });

  const transferirEmLote = ids.length >= QTD_MIN_TRANSFERIR_EM_LOTE;

  function transferirAsync(pvIds) {
    axios.post(`/api/pipeline/lead/enviarparatransferencia`, { leadIds: ids, preVendedorIds: pvIds });
    setTimeout(() => {
      createSnackbar(
        intl.formatMessage({
          defaultMessage:
            "Transferência enviada para processamento. Aguarde, em breve a sua solicitação será concluída.",
        })
      );
      onConfirm(true);
      AjaxBlackout.Hide();
    }, 10000);
  }

  function onSave() {
    const mensagemSucesso =
      ids.length > 1
        ? intl.formatMessage({ defaultMessage: "Leads transferidos com sucesso" })
        : intl.formatMessage({ defaultMessage: "Lead transferido com sucesso" });

    let pvIds = [];
    if (ids.length === 1) pvIds = [preVendedorIds];
    else pvIds = preVendedorIds;

    AjaxBlackout.Show();
    if (ids.length > 50) transferirAsync(pvIds);
    else
      axios
        .post(`/api/pipeline/lead/transferir`, { leadIds: ids, preVendedorIds: pvIds })
        .then(() => {
          createSnackbar(mensagemSucesso);
          onConfirm(true);
        })
        .catch(err => {
          createSnackAPIException(err);
          onConfirm(false);
        })
        .finally(AjaxBlackout.Hide);
  }

  let pvOptions = preVendedores;
  if (filter) {
    pvOptions = pvOptions.filter(filter);
  }

  const pvVinculado = pvs[0];
  const showForm = pvOptions.filter(x => x.id !== pvVinculado).length;

  const handleChangePreVendedores = param => {
    setPreVendedorIds(param.target.value);
  };

  const filtrarPreVendedores = items => {
    let lista = items;
    if (ids.length === 1) lista = lista.filter(x => x.id !== pvVinculado);
    if (filter) return lista.filter(filter);
    return lista;
  };

  return (
    <Loading isLoading={loading}>
      {!showForm && (
        <>
          <DialogContent>
            <DialogContentText>
              {intl.formatMessage(
                { defaultMessage: "O Lead já está vinculado ao Pré-Vendedor {pv} e não há outro disponível." },
                { pv: pvOptions[0]?.descricao }
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
          </DialogActions>
        </>
      )}

      {showForm && (
        <Formik
          initialValues={{ preVendedor: [{ id: pvVinculado }] }}
          onSubmit={transferirEmLote ? confirmarTransferencia : onSave}
          onReset={() => onCancel()}
          enableReinitialize
        >
          {() => (
            <Form>
              <DialogContent>
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <AsyncSelect
                      fullWidth
                      label={intl.formatMessage({ defaultMessage: "Pré-Vendedores" })}
                      url="/api/pipeline/usuario1/ListarNome?tipo=PreVendedor&somenteAtivos=true"
                      transformPayload={items => filtrarPreVendedores(items)}
                      getLabel={item => item.nome}
                      value={preVendedorIds}
                      onChange={handleChangePreVendedores}
                      enableSearch
                      searchLabel={intl.formatMessage({
                        defaultMessage: "Pesquisar por pré-vendedores",
                      })}
                      name="preVendedor"
                      type={typeSelect}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button id="btn-cancelar-transferencia" onClick={onCancel}>
                  {intl.formatMessage({ defaultMessage: "Cancelar" })}
                </Button>
                <Button id="btn-salvar-transferencia" color="primary" type="submit">
                  {intl.formatMessage({ defaultMessage: "Transferir" })}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Loading>
  );
}

Transferir.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  pvs: PropTypes.arrayOf(PropTypes.number),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  filter: PropTypes.func,
};

export default connect(({ pipeline }) => ({ ids: pipeline.modalTransferir.ids }))(Transferir);
