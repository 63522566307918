import React, { useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import {
  withStyles,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
  TextField as MTextField,
} from "@material-ui/core";
import { FieldArray, useFormikContext, Field } from "formik";
import { TextField } from "formik-material-ui";
import AddCircle from "@material-ui/icons/AddCircle";
import Close from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import useSWR from "swr";
import axios from "axios";

import { usePermission } from "../../../../../hooks";
import { Loading } from "../../../../../_common";
import { DISCOUNT_TYPE, TIPO_RECEITA } from "../../../../../_common/constantes";
import calculatePrice from "../calculatePrice";
import { appendLabelInativo, formatCurrency } from "../../../../../_common/utils/string";
import { positive } from "../../../../../_common/utils/forms/validacoes";
import PositiveIntegerInput from "../../../../../components/PositiveIntegerInput";
import CurrencyInput from "../../../../../components/CurrencyInput";
import PercentInput from "../../../../../components/PercentInput";
import permissions from "../../../../../_common/permissions";
import { currencyToNumber } from "../../../../../_common/utils/currency";
import FormikSelectProduto from "../../../../../components/FormikSelectProduto";
import { FormikAsyncSelect } from "../../../../../components/AsyncSelect";

EditProducts.propTypes = {
  name: PropTypes.string,
  lead: PropTypes.object,
  classes: PropTypes.object,
  vendido: PropTypes.bool,
  cfgProdutos: PropTypes.shape({
    exigirProdutoNaVenda: PropTypes.bool,
    exigirValorProdutoNaVenda: PropTypes.bool,
  }),
};

const CAMPO_OBRIGATORIO = "Campo obrigatório";
const valueReducer = (ac, { quantidade, desconto, valor, tipoDesconto }) =>
  ac + calculatePrice(valor, desconto, tipoDesconto, quantidade);

const EMPTY_PRODUCT = { quantidade: 1, desconto: 0, tipoDesconto: DISCOUNT_TYPE.NONE, id: 0, valor: 0 };

function EditProducts({ name = "produtos", lead, vendido = false, cfgProdutos = null, classes }) {
  const intl = useIntl();
  const canEdit = usePermission(permissions.ALTERAR_DOR_PRODUTO, lead);

  const { data: produtos, error: produtosError, mutate: recarregarListaProduto } = useSWR(
    vendido ? `/api/pipeline/produto/listar?leadId=${lead.id}` : "/api/pipeline/produto/listar",
    {
      fetcher: (...args) => axios.get(...args).then(({ data }) => data.map(item => appendLabelInativo(item, intl))),
    }
  );

  const {
    values: { [name]: values },
    setFieldValue,
  } = useFormikContext();

  const validateProduto = produtoId => {
    const currentProduto = produtos.find(produto => +produto.id === +produtoId);
    if (!currentProduto?.ativo) return intl.formatMessage({ defaultMessage: "O produto está inativo." });

    return undefined;
  };

  const validateValor = valor => {
    if (cfgProdutos?.exigirValorProdutoNaVenda) {
      const valorFormatado =
        parseFloat(
          String(valor)
            .replace(/[^0-9,.]/g, "")
            .replace(",", ".")
        ) || 0;

      if (valorFormatado === 0) {
        return intl.formatMessage({ defaultMessage: CAMPO_OBRIGATORIO });
      }
    }

    return undefined;
  };

  const filter = useCallback(produto => !values.some(({ id }) => id === produto.id), [values]);

  const available = useMemo(() => produtos?.filter(filter), [filter, produtos]);
  return (
    <Loading isLoading={!produtos && !produtosError}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.width30}>
              <span className={cfgProdutos?.exigirProdutoNaVenda && !values.length ? classes.error : ""}>
                {intl.formatMessage({ defaultMessage: "Produto" })}
              </span>

              {cfgProdutos?.exigirProdutoNaVenda &&
                !values.length && (
                  <Typography variant="caption" color="error" className={classes.errorText}>
                    {intl.formatMessage({ defaultMessage: CAMPO_OBRIGATORIO })}
                  </Typography>
                )}
            </TableCell>
            <TableCell className={classes.width10}>
              <span className={cfgProdutos?.exigirValorProdutoNaVenda && !values.length ? classes.error : ""}>
                {intl.formatMessage({ defaultMessage: "Valor" })}
              </span>

              {cfgProdutos?.exigirValorProdutoNaVenda &&
                !values.length && (
                  <Typography variant="caption" color="error" className={classes.errorText}>
                    {intl.formatMessage({ defaultMessage: CAMPO_OBRIGATORIO })}
                  </Typography>
                )}
            </TableCell>
            <TableCell className={classes.width10}>{intl.formatMessage({ defaultMessage: "Quantidade" })}</TableCell>
            <TableCell className={classes.width10}>
              {intl.formatMessage({ defaultMessage: "Tipo de Desconto" })}
            </TableCell>
            <TableCell className={classes.width10}>{intl.formatMessage({ defaultMessage: "Desconto" })}</TableCell>
            <TableCell className={classes.width10}>{intl.formatMessage({ defaultMessage: "Receita" })}</TableCell>
            <TableCell className={classes.width30}>{intl.formatMessage({ defaultMessage: "Observações" })}</TableCell>
            <TableCell className={classes.width10}>{intl.formatMessage({ defaultMessage: "Total" })}</TableCell>
          </TableRow>
        </TableHead>
        <FieldArray name={name}>
          {({ push, remove, replace }) => (
            <TableBody>
              {!values.length && (
                <TableRow>
                  <TableCell colSpan="5" align="center">
                    <Typography color="textSecondary">
                      {intl.formatMessage({ defaultMessage: "Não há produtos cadastrados" })}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              {values.map((value, index) => (
                <TableRow hover key={index} className={classes.row}>
                  <TableCell className={classes.width30}>
                    <FormikSelectProduto
                      name={`${name}.${index}.id`}
                      options={produtos}
                      disabled={!canEdit}
                      fullWidth
                      enableSearch
                      filter={filter}
                      onChange={(e, p) => replace(index, { ...EMPTY_PRODUCT, ...p })}
                      getDisabled={item => !item.ativo}
                      validate={produtoId => validateProduto(produtoId)}
                      criarNovo
                      urlCriarNovo="/spotter/configuracoes/padroes"
                      funcaoRecarregar={recarregarListaProduto}
                    />
                  </TableCell>
                  <TableCell className={classes.width10}>
                    <Field
                      validate={valor => validateValor(valor)}
                      disabled={!canEdit}
                      name={`${name}.${index}.valor`}
                      component={TextField}
                      fullWidth
                      InputProps={{ inputComponent: CurrencyInput }}
                    />
                  </TableCell>
                  <TableCell className={classes.width10}>
                    <Field
                      disabled={!canEdit}
                      name={`${name}.${index}.quantidade`}
                      component={TextField}
                      fullWidth
                      validate={v => positive(v)}
                      InputProps={{ inputComponent: PositiveIntegerInput }}
                    />
                  </TableCell>
                  <TableCell className={classes.width10}>
                    <Field
                      disabled={!canEdit}
                      component={MTextField}
                      value={String(value.tipoDesconto)}
                      onChange={e => {
                        setFieldValue(`${name}.${index}.tipoDesconto`, Number(e.target.value));
                        if (String(value.tipoDesconto) !== e.target.value)
                          setFieldValue(`${name}.${index}.desconto`, 0);
                      }}
                      fullWidth
                      name={`${name}.${index}.tipoDesconto`}
                      select
                    >
                      <MenuItem value={String(DISCOUNT_TYPE.NONE)}>
                        {intl.formatMessage({ defaultMessage: "Nenhum" })}
                      </MenuItem>
                      <MenuItem value={String(DISCOUNT_TYPE.PERCENT)}>
                        {intl.formatMessage({ defaultMessage: "Percentual" })}
                      </MenuItem>
                      <MenuItem value={String(DISCOUNT_TYPE.ABSOLUTE)}>
                        {intl.formatMessage({ defaultMessage: "Absoluto" })}
                      </MenuItem>
                    </Field>
                  </TableCell>
                  <TableCell className={classes.width10}>
                    {value.tipoDesconto === DISCOUNT_TYPE.ABSOLUTE ? (
                      <Field
                        component={TextField}
                        fullWidth
                        name={`${name}.${index}.desconto`}
                        disabled={!canEdit || value.tipoDesconto === DISCOUNT_TYPE.NONE}
                        InputProps={{
                          inputComponent: CurrencyInput,
                          inputProps: {
                            isAllowed: ({ floatValue }) => {
                              const valor =
                                typeof value.valor === "string" ? currencyToNumber(value.valor) : value.valor;
                              return floatValue <= valor * value.quantidade;
                            },
                          },
                        }}
                      />
                    ) : (
                      <Field
                        component={TextField}
                        fullWidth
                        name={`${name}.${index}.desconto`}
                        disabled={!canEdit || value.tipoDesconto === DISCOUNT_TYPE.NONE}
                        InputProps={{ inputComponent: PercentInput }}
                      />
                    )}
                  </TableCell>
                  <TableCell className={classes.width10}>
                    <FormikAsyncSelect
                      fullWidth
                      disabled={!canEdit}
                      getId={item => item.id}
                      getInputLabel={item => intl.formatMessage(item.label)}
                      value={value.tipoReceita}
                      options={TIPO_RECEITA}
                      enableSearch
                      searchLabel={intl.formatMessage({ defaultMessage: "Pesquisar por tipos" })}
                      name={`${name}.${index}.tipoReceita`}
                      type="nullable"
                    />
                  </TableCell>
                  <TableCell className={classes.width30}>
                    <Field disabled={!canEdit} name={`${name}.${index}.observacao`} component={TextField} fullWidth />
                  </TableCell>
                  <TableCell className={classes.width10}>
                    {formatCurrency(calculatePrice(value.valor, value.desconto, value.tipoDesconto, value.quantidade))}
                  </TableCell>
                  <TableCell className={classes.width10}>
                    <IconButton disabled={!canEdit} onClick={() => remove(index)} className={classes.actions}>
                      <Close />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell colSpan="2">
                  <Tooltip
                    title={
                      !available.length
                        ? intl.formatMessage({
                            defaultMessage: "Todos os produtos já foram adicionados",
                          })
                        : ""
                    }
                  >
                    <span>
                      <IconButton
                        disabled={!canEdit || !available.length}
                        onClick={() =>
                          push({
                            ...EMPTY_PRODUCT,
                            ...available[0],
                          })
                        }
                        color="primary"
                        className={classes.backgroundNone}
                      >
                        <AddCircle />
                        {intl.formatMessage({ defaultMessage: "Adicionar produto" })}
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell colSpan="2" align="right">
                  <Typography>{intl.formatMessage({ defaultMessage: "Soma dos produtos" })}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{formatCurrency(values.reduce(valueReducer, 0))}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </FieldArray>
      </Table>
    </Loading>
  );
}

export default withStyles({
  width10: { width: "10%", padding: "4px 8px" },
  width20: { width: "20%", padding: "4px 8px" },
  width30: { width: "30%", padding: "4px 8px" },
  backgroundNone: { background: "none" },
  selectProduto: { maxWidth: 300 },
  actions: { visibility: "hidden" },
  row: { "&:hover $actions": { visibility: "visible" } },
  field: { textAlign: "center" },
  errorText: { whiteSpace: "nowrap" },
  error: { color: "#f46259" },
})(EditProducts);
