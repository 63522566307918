import React from "react";
import PropTypes from "prop-types";
import { Form } from "formik";

import { useIntl } from "react-intl";
import { ClearInput } from "../../../../components";

function FormObservacoes({ values, setValues }) {
  const intl = useIntl();

  return (
    <Form>
      <ClearInput
        label={intl.formatMessage({ defaultMessage: "Observações" })}
        name="observacoes"
        hasValue={!!values.observacoes && !!values.observacoes.length}
        clearValue={() => setValues({ ...values, observacoes: "" })}
        multiline
        rows={4}
        inputProps={{ maxLength: 8000 }}
      />
    </Form>
  );
}

FormObservacoes.propTypes = {
  values: PropTypes.object,
  setValues: PropTypes.func,
};

export default FormObservacoes;
