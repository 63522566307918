/* eslint-disable no-param-reassign */
import { DISCOUNT_TYPE } from "../../../../_common/constantes";
import { currencyToNumber } from "../../../../_common/utils/currency";

export default function(value = 0, discount = 0, type = 0, amount = 0) {
  if (typeof discount === "string") discount = currencyToNumber(discount);

  if (typeof value === "string") value = currencyToNumber(value);

  if (typeof amount === "string") amount = Number(amount);
  let price = value * amount;

  if (type === DISCOUNT_TYPE.ABSOLUTE) price -= discount;
  else if (type === DISCOUNT_TYPE.PERCENT) price *= 1 - discount / 100;

  return price;
}
